@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "IBMPlexSansRegular";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/IBMPlexSans/IBMPlexSans-Regular.ttf") format("woff");
}

@font-face {
  font-family: "IBMPlexSansMedium";
  font-style: normal;
  font-weight: 500;
  src: url("./assets/fonts/IBMPlexSans/IBMPlexSans-Medium.ttf") format("woff");
}

body {
  margin: 0;
  font-family: "IBMPlexSansRegular", sans-serif;
  color: #030303;
}

.react-datepicker-wrapper {
  width: 100%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
